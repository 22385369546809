import React, { createElement } from 'react';

import { ClientOnly } from '@atc/react-client-only';

import { LazyRender } from 'reaxl';

function LazyComponent({

    children,
    componentClass: Component = 'div',
    componentProps,
    height,
    style,
    fallback = null,
    threshold,
    renderOnScroll = true,
    ...rest
}) {

    const markerStyle = { ...style };
    if (height) {
        markerStyle.height = height;
    }
    const LazyRenderMarker = createElement(Component, {
        style: markerStyle,
        ...componentProps,
        ...rest,
    }, fallback);

    return (
        <ClientOnly
            fallback={LazyRenderMarker}
            {...rest}
        >
            {renderOnScroll && (
                <LazyRender
                    fallback={fallback}
                    componentClass={Component}
                    componentProps={componentProps}
                    threshold={threshold}
                >
                    {children}
                </LazyRender>
            )}
            {!renderOnScroll && children}
        </ClientOnly>
    );
}

export default LazyComponent;
